@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

@layer base {
  *,
  a,
  input,
  button {
    font-family: "Lato", sans-serif;
    scroll-behavior: smooth;
    -webkit-tap-highlight-color: transparent;
  }
  .cross_fade {
    animation-name: cross-fade;
    animation-direction: alternate-reverse;
    animation-play-state: running;
    animation-timing-function: ease-in-out;
    animation-duration: 40s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
  }
  @keyframes cross-fade {
    /* 0% {
      background-image: url("./assets/images/trava-send-package.png");
    }
    20% {
      background-image: url("./assets/images/trava-service-1.png");
    }
    40% {
      background-image: url("./assets/images/trava-send-package.png");
    }
    60% {
      background-image: url("./assets/images/trava-service-1.png");
    }
    80% {
      background-image: url("./assets/images/trava-send-package.png");
    }
    100% {
      background-image: url("./assets/images/trava-service-1.png");
    } */
  }

  .fade_in {
    transition: all 5s;
    animation-name: fade-in;
    animation-timing-function: ease-in-out;
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
  }
  .fade_out {
    display: none !important;
    transition: all 5s;
    animation-name: fade-out;
    animation-timing-function: ease-in-out;
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
  }
  @keyframes fade-in {
    0% {
      opacity: 0.3;
    }

    100% {
      opacity: 1;
    }
  }
  @keyframes fade-out {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
}
body {
  @apply bg-[#fcfcfc];
}
.embla {
  @apply overflow-hidden;
}
.embla__container {
  @apply flex;
}
.embla__slide {
  @apply min-w-0 flex-[0_0_100%];
}
.embla__dots {
  @apply flex flex-wrap items-center;
}
.embla__dot {
  @apply w-2.5 h-2.5 border-none cursor-pointer inline-flex justify-center items-center rounded-full touch-manipulation bg-black-100 mr-2 appearance-none;
}

.embla__dot--selected {
  @apply bg-black-400;
}
