/* Basic page styling */
.delete-account-page {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .delete-account-box {
    background-color: #fff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 400px;
    max-width: 100%;
  }
  
  .form-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
  }
  
  p {
    margin-bottom: 20px;
    font-size: 14px;
    color: #555;
  }
  
  /* Checkbox group styling */
  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .ant-checkbox-wrapper {
    font-size: 14px;
  }
  
  /* Textarea styling */
  .ant-input-textarea {
    margin-top: 20px;
  }
  
  .ant-input-textarea textarea {
    border-radius: 6px;
    resize: none;
  }
  
  /* Button styling */
  .delete-form-button {
    width: 100%;
    background-color: #17123b;
    border: none;
    color: white;
    font-size: 16px;
    height: 40px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    transition: background-color 0.3s ease;
  }
  
  .delete-form-button:hover {
    background-color: #40a9ff;
  }
  
  /* Responsive adjustments */
  @media screen and (max-width: 768px) {
    .delete-account-box {
      padding: 20px;
    }
  }
  